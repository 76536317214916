<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Filter" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item px-3" v-if="escrowBankFilterDisplay != 'None'">
                            <div class="filter-name">Escrow Account:</div>
                            <div class="filter-value">{{escrowBankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item px-3" v-if="regionFilterDisplay != 'None'">
                            <div class="filter-name">Region:</div>
                            <div class="filter-value">{{regionFilterDisplay}}</div>
                        </li>
                        <li class="nav-item px-3" v-if="regionFilterDisplay != 'None'">
                            <div class="filter-name">Branch:</div>
                            <div class="filter-value">{{branchFilterDisplay}}</div>
                        </li>
                        <li class="nav-item px-3" v-if="typeFilterDisplay != 'None'">
                            <div class="filter-name">Type:</div>
                            <div class="filter-value">{{typeFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Reset Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-4 col-lg-4 col-xl-4 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="Select Escrow Account..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-4 col-lg-4 col-xl-4 form-group">
                    <label for="dtp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_region', id: 'dtp_region' }"
                        :items="regions"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.regionID"
                        placeholder="Region..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-4 col-lg-4 col-xl-4 form-group">
                    <label for="dtp_branch">Branch</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_branch', id: 'dtp_branch' }"
                        :items="branches"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.branchID"
                        placeholder="Select Branch..."
                        :disabled="request.regionID==null"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-4 col-lg-4 col-xl-4 form-group">
                    <label for="dtp_money_movement_type">Approval Type</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_money_movement_type', id: 'dtp_money_movement_type' }"
                        :items="moneyMovementTypes"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.moneyMovementTypeID"
                        :show-clear-button="true"
                        placeholder="Select Type..."
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_pending-approvals"
            :actions="selectionActions"
            :config="gridConfig"
            title="Pending Approvals"
            class="grid-container"
            title-size="sm"
            :data-source="gridDataSource"
            @approve="onApprove"
            @reject="onReject"
            @navigate="onGotoFile"
            :strikethrough-if-true="['approvalDisabled']"
            integrated-search
            rq-filters>
            <template #toolbar>
                <ul class="nav navbar-nav me-3 ms-auto">
                    <li class="nav-item">
                        <b-form-checkbox
                            automation_id="chk_show_all"
                            id="chk_show_all"
                            v-model="showAll"
                        >Show All Pending Approvals</b-form-checkbox>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { PendingApprovalDto, PendingApprovalSearchRequest } from "../models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { MoneyMovementType, MoneyMovementBatchApprovalType } from '@config/enums';
    import MoneyMovementApprovalForm from "../components/MoneyMovementApprovalForm";

    export default {
        name: "MoneyMovementApprovalList",
        mixins: [GridSystemLookupMixin],

        data () {
            return {
                request: new PendingApprovalSearchRequest(),
                activeRequest: new PendingApprovalSearchRequest(),
                items: [],
                showAll: false,
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
            };
        },

        created() {
            this.fetchData()
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        // beforeRouteLeave(to, from, next) {
        //     next();
        // },

        watch: {
            "request.regionID" (newValue, oldValue) {
                if(_.isEqual(newValue, oldValue) || _.isNil(oldValue)) return;
                this.request.branchID = _.isEqual(newValue, this.user.regionID) ? this.user.branchID : null;
                this.branches = this.lookupHelpers.getBranches(newValue);
            },
            showAll(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refresh();
            },
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            filteredItems() { return this.showAll ? this.items : _.filter(this.items, ['approvalDisabled', false]); },
            moneyMovementTypes(){ return MoneyMovementType.lookupItems; },
            // hasFilter(){
            //     return !_.isEqual(new PendingApprovalSearchRequest(this.request), new PendingApprovalSearchRequest());
            // },
            hasActiveFilter(){
                return !_.isEqual(new PendingApprovalSearchRequest(this.activeRequest), new PendingApprovalSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new PendingApprovalSearchRequest(this.activeRequest), new PendingApprovalSearchRequest(this.request));
            },
            regionFilterDisplay(){
                return this.activeRequest.regionID == 0 ? "All" :this.lookupHelpers.getLookupItemName(this.lookupItems.REGIONS, this.activeRequest.regionID);
            },
            branchFilterDisplay(){
                return this.activeRequest.branchID == 0 ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.BRANCHES, this.activeRequest.branchID);
            },
            escrowBankFilterDisplay(){
                return this.activeRequest.bankCompanyID == 0 ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.USER_ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            typeFilterDisplay(){
                return this.activeRequest.moneyMovementTypeID == 0 ? "All" : MoneyMovementType.displayValue(this.activeRequest.moneyMovementTypeID);
            },
        },

        methods: {
            approveDisabled(items) {
                if (_.some(items, { approvalDisabled: true })) return "Access Restricted";
                if (_.some(items, { moneyMovementBatchApprovalType: MoneyMovementBatchApprovalType.None }) && _.size(items) > 1) return "Access Restricted";
                const firstOrdersID = items[0]?.ordersID || 0;
                if (_.some(items, { moneyMovementBatchApprovalType: MoneyMovementBatchApprovalType.File }) && _.size(items) > 1 && !_.every(items, { ordersID: firstOrdersID })) return "Batch approvals must be from the same file";
                return false;
            },
            autoBatchApprove(items){
                const self = this;
                let apiPromise = self.$api.MoneyMovementApprovalsApi.submitMoneyMovementApproval(self.user.usersID, true, items);
                return self.$rqBusy.wait(apiPromise);
            },
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },
            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new PendingApprovalSearchRequest();
                this.activeRequest = new PendingApprovalSearchRequest();
                this.items = [];
                this.refresh();
            },
            onSearch() {
                if(!this.hasFilterChanged) {
                    //this.onClearSearch();
                    return;
                }
                this.fetchData();
            },
            onGotoFile(e) {
                if(!e || !e.data) return;
                this.$router.push({ name: "check-writing", params: { orderId: e.data.ordersID, tab: 2 } });
            },
            fetchData() {
                const self = this;
                self.items = [];
                let bankCompanyID = _.parseNumber(self.request.bankCompanyID, 0);
                let regionID = _.parseNumber(self.request.regionID, 0);
                let branchID = _.parseNumber(self.request.branchID, 0);
                let moneyMovementTypeID = _.parseNumber(self.request.moneyMovementTypeID, 0);
                let apiPromise = self.$api.MoneyMovementApprovalsApi.getPendingApprovals(bankCompanyID, regionID, branchID, moneyMovementTypeID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setData(result);
                        self.activeRequest = new PendingApprovalSearchRequest(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Pending Approval";
                self.itemTypeNamePlural = "Pending Approvals";
                self.itemKey = "moneyMovementApprovalID";
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.regions = self.lookupHelpers.getLookupItems(self.lookupItems.REGIONS);
                self.branches = self.lookupHelpers.getLookupItems(self.lookupItems.BRANCHES);
                self.selectionActions = [
                    {
                        name: "approve",
                        text: "Approve",
                        eventName: "approve",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) { return self.approveDisabled(e.data); }
                    },
                    {
                        name: "reject",
                        text: "Reject",
                        eventName: "reject",
                        requireSelection: true,
                        disabled: function(e) { return e.data.approvalDisabled; }
                    },
                    {
                        name: "goto",
                        text: "Go To File",
                        eventName: "navigate",
                        requireSelection: true,
                        tooltip: `Go to Check Writing for this File.`,
                    },
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "requestedOn",
                            dataType: "datetime",
                            caption: "Date",
                            cellTemplate: DxGridUtils.dateTimeCellTemplate
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#"
                        },
                        {
                            dataField: "requestorName",
                            dataType: "string",
                            caption: "Requestor"
                        },
                        {
                            dataField: "checkAmount",
                            caption: "Current Amount",
                            dataType: "number",
                            visible: false,
                            showInColumnChooser: true,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "requestedAmount",
                            caption: "Requested Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.USER_ESCROW_ACCOUNTS
                        }),
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "regionID",
                                dataType: "number",
                                caption: "Region",
                                visible: false,
                                showInColumnChooser: true,
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.REGIONS
                        }),
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "branchID",
                                dataType: "number",
                                caption: "Branch",
                                visible: false,
                                showInColumnChooser: true,
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.BRANCHES,
                        }),
                        {
                            dataField: "moneyMovementTypeID",
                            dataType: "number",
                            caption: "Type",
                            visible: false,
                            showInColumnChooser: true,
                            lookup: {
                                dataSource: self.moneyMovementTypes,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                        dataField: "approvalDisabled",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate,
                        showInColumnChooser: false,
                        visible: false
                        }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.filteredItems);
                    },
                };
            },
            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },
            onApprove(e) {
                this.errorMessage = "";
                let itemCount = _.size(e.data);
                if (itemCount == 1) {
                    this.showMoneyMovementApprovalDialog(e.data[0], true);
                } else {
                    this.autoBatchApprove(e.data)
                        .then(result => {
                            if (result) {
                                this.$toast.success({ message: `${itemCount} items approved.` });
                            } else {
                                this.$toast.error({ message: `One or more items failed to be batch approved.` });
                            }
                            this.fetchData();
                        })
                        .catch(error => {
                            this.$toast.error({ message: `One or more items failed to be batch approved.` });
                            console.log(error);
                        });
                }
            },
            onReject(e) {
                this.errorMessage = "";
                this.showMoneyMovementApprovalDialog(e.data, false);
            },
            showMoneyMovementApprovalDialog(item, approving) {
                const self = this;
                let noun = MoneyMovementType.displayValue(item.moneyMovementTypeID);
                let verb = approving ? "Approve" : "Reject";
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.$toast.success({ message: `${noun} was ${approving ? "approved" : "rejected"}.` });
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    self.refresh();
                    return true;
                };
                let title = `${verb} ${noun}`;
                self.$dialog.open({
                    title: title,
                    width: "80%",
                    adaptive: true,
                    // height: "80%",
                    closeOnEsc: true,
                    component: MoneyMovementApprovalForm,
                    props: {approving: approving, item: item },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: verb, automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: onOk }
                    ],
                });
            },
            setData(data) {
                const self = this;
                self.items = _.map(data, i => new PendingApprovalDto(i));
            },
            validate() {
                this.errorMessage = "";
            },
        }
    }
</script>
